import React from 'react'
import './Header.css'
import { NavLink } from 'react-router-dom'
import Logo from './../../Images/logostacked.png'

function Header() {
  return (
    <>
      <section className='header px-3 d-flex align-items-center justify-content-between'>
        <NavLink to='/'>
            <img src={Logo} alt='Logo' />
        </NavLink>
        <div>
            <h1 className='text-white text-end'>ImmiAccount</h1>
        </div>
      </section>
    </>
  )
}

export default Header
