import React, { useEffect, useState } from 'react';
import './VisaPage.css';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeaderVevo from '../../Components/HeaderVevo/HeaderVevo';

function VisaPage() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check for a valid token
    const userToken = localStorage.getItem('token');

    if (!userToken) {
      // Redirect to login if the token is not present
      navigate('/');
    } else {
      // Fetch user data using the token
      axios.get('https://vevovisa.com/getUserData.php', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userToken,
        },
      })
        .then(response => {
          if (response.data.success) {
            setUserData(response.data.user);
            console.log('User data:', response.data.user);
          } else {
            console.error('Failed to fetch user data:', response.data.message);
          }
        })
        .catch(error => {
          console.error('An error occurred while fetching user data:', error);
        });
    }
  }, [navigate]);

  const handleDownloadPDF = () => {
    window.open(`https://vevovisa.com/uploads/${userData?.visaPdf}`, '_blank');
  };
  return (
    <>
      <HeaderVevo/>
      <section className='mainSec'>
        <div className="formTitle">
          <p className='fw-bold'>Visa Status</p>
        </div>

        <div className="visaDetail">
          <p>Congratulations, {userData?.name}! Your visa application has been approved. To view and download your visa, please click the button below. Safe travels and best wishes on your upcoming journey.</p>
          <button onClick={handleDownloadPDF} className='visaButton'>Download Visa</button>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default VisaPage;
