import React, { useEffect, useState } from 'react'
import './MyApplication.css'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Accordion from 'react-bootstrap/Accordion';
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios';

const CustomIcon = ({ expanded }) => {
  return (
    <>
    <h3 style={{width: '50px'}}>-</h3>
    </>
  );
};

function MyApplication() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check for a valid token
    const userToken = localStorage.getItem('token');

    if (!userToken) {
      // Redirect to login if the token is not present
      navigate('/');
    } else {
      // Fetch user data using the token
      axios.get('https://vevovisa.com/getUserData.php', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': userToken,
        },
      })
        .then(response => {
          if (response.data.success) {
            setUserData(response.data.user);
            console.log('User data:', response.data.user);
          } else {
            console.error('Failed to fetch user data:', response.data.message);
          }
        })
        .catch(error => {
          console.error('An error occurred while fetching user data:', error);
        });
    }
  }, [navigate]);

  const [expanded, setExpanded] = React.useState(false);
  return (
    <>
      <Header/>
      <section className='mainSec'>
        <div className="formTitle">
            <p className='fw-bold'>My Applications Summary</p>
        </div>

        <div className="appContent">
          <h5 className='mb-4 mt-2'>List of applications</h5>

          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div style={{display: 'flex', width: '100%'}}>
                  <CustomIcon expanded={expanded} />
                  <div style={{maxWidth: '500px', lineHeight: '15px', width: '100%'}}>
                    <span style={{fontSize: '16px', color: '#024da1'}}>{userData?.name} ({userData?.dob})</span><br/>
                    <span style={{fontSize: '12px', color: 'black'}}>Temporary Work (Activity)</span><br/>
                    <b style={{fontSize: '12px', color: 'green', fontWeight: '500'}}>Payment Successful</b>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className='tableCol'>
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td style={{width: '180px'}}>Reference No</td>
                          <td style={{width: '300px'}}>{userData?.referenceNo}</td>
                        </tr>
                        <tr>
                          <td style={{width: '180px'}}>Type</td>
                          <td style={{width: '300px'}}>Temporary Work - Short Stay Specialist Visa (400)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <table>
                      <tr>
                        <td style={{width: '180px'}}>Date  Submitted</td>
                        <td style={{width: '300px'}}>{userData?.dateUpdates}</td>
                      </tr>
                      <tr>
                        <td style={{width: '180px'}}>Status</td>
                        <td style={{width: '300px', color: 'green', fontWeight: 'medium'}}>{userData?.status}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        <div className='statusButton'>
            <NavLink to='/vevo-visa'>
                <button name='continue'>Check Visa</button>
            </NavLink>
        </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default MyApplication
