import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from './Pages/LandingPage';
import LoginSuccess from './Pages/LoginSuccess/LoginSuccess';
import MyApplication from './Pages/MyApplication/MyApplication';
import VisaPage from './Pages/VisaPage/VisaPage';
import AddUser from './Pages/AddUser/AddUser';
import VevoHolder from './Pages/VevoHolder/VevoHolder';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<LandingPage/>} />
      <Route path='/login' element={<LoginSuccess/>} />
      <Route path='/app' element={<MyApplication/>} />
      <Route path='/visa' element={<VisaPage/>} />
      <Route path='/add-new-vevo' element={<AddUser/>} />
      <Route path='/vevo-visa' element={<VevoHolder/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
