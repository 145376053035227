import React, { useEffect, useState } from 'react';
import './VevoHolder.css';
import HeaderVevo from '../../Components/HeaderVevo/HeaderVevo';
import Footer from '../../Components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function VevoHolder() {
  const navigate = useNavigate();

  useEffect(() => {
    // Check for a valid token
    const userToken = localStorage.getItem('token');

    if (!userToken) {
      // Redirect to login if the token is not present
      navigate('/');
    } else {
      // Perform additional actions or fetch user data using the token
      console.log('Token exists:', userToken);
    }
  }, [navigate]);

  const [selectedDocType, setSelectedDocType] = useState('');
  const [selectedRefType, setSelectedRefType] = useState('');
  const [formData, setFormData] = useState({
    docType: '',
    refType: '',
    grantNo: '',
    dob: '',
    passport: '',
    agree: false,
  });
  const [error, setError] = useState('');

  const handleDocTypeChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedDocType(selectedValue);
  
    setFormData((prevData) => ({
      ...prevData,
      docType: selectedValue,
      refType: '', // Reset refType when docType changes
    }));
  };
  
  const handleRefTypeChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedRefType(selectedValue);
  
    setFormData((prevData) => ({
      ...prevData,
      refType: selectedValue,
    }));
  };
  

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  
  
  const handleSubmit = async (e) => {
    const userToken = localStorage.getItem('token');
    e.preventDefault();
    console.log('Form Data:', formData);
  
    // Validation logic
    if (
      formData.docType === 'passport' &&
      formData.refType === 'visa-grant' &&
      formData.grantNo &&
      formData.dob &&
      formData.passport &&
      formData.agree
    ) {
      // Data is valid, submit to server
      try {
        const response = await axios.post(
          'https://vevovisa.com/submit-vevo-form.php',
          formData, // Stringify the formData
          {
            headers: {
              'Content-Type': 'application/json', // Set the Content-Type header
              Authorization: userToken,
            },
          }
        );
  
        if (response.data.success) {
          // Redirect to Visa page
          navigate('/visa');
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError('An error occurred while submitting the form.');
      }
    } else {
      setError('Please fill in all required fields.');
    }
  };
  
  

  return (
    <>
      <HeaderVevo />
      <div className='vevoSec'>
        <div className="formTitle">
          <p className='fw-bold'>Visa holder enquiry</p>
        </div>

        <div className='vevoContent'>
          <p className='mb-0'>Please complete the following details to view your visa entitlements.</p>
          <p>Fields marked <span style={{ color: '#ee0000' }}>*</span> must be completed.</p>

          <form className='vevoTable' onSubmit={handleSubmit}>
            <table style={{ marginBottom: '30px' }}>
              <tr>
                <td className='dataLeft'>Document Type <span style={{ color: '#ad1a1f' }}>*</span></td>
                <td className='dataRight'>
                  <select name="docType" onChange={handleDocTypeChange} value={selectedDocType}>
                    <option value="">Select document type</option>
                    <option value="passport">Passport</option>
                  </select>
                </td>
              </tr>

              {selectedDocType === 'passport' && (
                <>
                  <tr>
                    <td className='dataLeft'>Reference type <span style={{ color: '#ad1a1f' }}>*</span></td>
                    <td className='dataRight'>
                      <select name="refType" onChange={handleRefTypeChange} value={selectedRefType}>
                        <option value="">Select reference type</option>
                        <option value="visa-grant">Visa Grant Number</option>
                      </select>
                    </td>
                  </tr>
                  {selectedRefType === "visa-grant" && (
                    <>
                      <tr>
                        <td className='dataLeft'>Visa Grant Number <span style={{ color: '#ad1a1f' }}>*</span></td>
                        <td className='dataRight'>
                          <input type="text" name="grantNo" style={{ maxWidth: '400px' }} onChange={handleInputChange} />
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td className='dataLeft'>Date of birth <span style={{ color: '#ad1a1f' }}>*</span></td>
                    <td className='dataRight'>
                      <input type="text" name="dob" style={{ maxWidth: '300px' }} onChange={handleInputChange} />
                    </td>
                  </tr>
                  <tr>
                    <td className='dataLeft'>Document Number <span style={{ color: '#ad1a1f' }}>*</span></td>
                    <td className='dataRight'>
                      <input type="text" name="passport" style={{ maxWidth: '400px' }} onChange={handleInputChange} />
                    </td>
                  </tr>
                  <tr>
                    <td className='dataLeft'></td>
                    <td className='dataRight d-flex align-items-center gap-2'>
                    <input
                      type="checkbox"
                      name="agree"
                      style={{ width: 'fit-content' }}
                      checked={formData.agree}
                      onChange={handleInputChange}
                    />

                      <label htmlFor='agree'><span style={{ color: '#ad1a1f' }}>*</span> I have read and agree to the terms and conditions</label>
                    </td>
                  </tr>
                </>
              )}

            </table>

          {error && <div className="error-message text-danger">{error}</div>}

          <div className='buttonBar'>
            <button type="submit" name='clear'>Clear</button>
            <button type="submit" name='submit'>Submit</button>
          </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VevoHolder;
