import React, { useEffect } from 'react'
import './LoginSuccess.css'
import { FaInfoCircle } from "react-icons/fa"
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import { NavLink, useNavigate } from 'react-router-dom'

function LoginSuccess() {
    const navigate = useNavigate();

    useEffect(() => {
        // Check for a valid token
        const userToken = localStorage.getItem('token');

        if (!userToken) {
            // Redirect to login if the token is not present
            navigate('/')
        } else {
            // Perform additional actions or fetch user data using the token
            console.log('Token exists:', userToken);
        }
    }, [navigate]);
    
  return (
    <>
      <Header/>
      <section className='mainSec'>
        <div className="formTitle">
            <h6 className='fw-bold'>Login Successful</h6>
        </div>
        
        <div className="infoSec">
            <div className="infoDiv">
                <div className="infoTitle">
                    <h6>
                        <i><FaInfoCircle /></i>
                        Information
                    </h6>
                </div>
                <div className='infoContent'>
                    <h3>Skilled-Recognised Graduate (subclass 476) visa application is closing - 22 December 2023</h3>
                    <p>The Skilled-Recognised Graduate (subclass 476) visa has been capped from 22 December 2023.</p>
                    <p>Applications lodged from 22 December 2023 will be finalised as invalid, and no further applications will be granted this program year.</p>
                    <p>If you have an application pending with the Department, you will receive instructions on how to claim a repayment of your Visa Application Charge (VAC) through ImmiAccount.</p>

                    <h3 className='mt-5'>Online Prepayment Portal issue</h3>
                    <p>Some ImmiAccount users are experiencing difficulty making a pre-payment for a paper application.</p>
                    <p>When selecting the 'Calculate' button on the <b>Pre-pay Paper Service</b> you might encounter the following issues:</p>
                    <ul>
                        <li>If you are using an Apple mobile device, you may see the error message: <i><b>Error while processing form action, please contact administrator.</b></i></li>
                        <li>If you are using an android device, the 'Calculate' button will not do anything and no error message will display.</li>
                    </ul>
                    <p>We recommend that you use a laptop or desktop device to make the payment.</p>
                    <p>Alternatively, you can manually enter the exact amount in the Amount (AUD) field. The system will then allow you to submit the payment online.</p>
                    <p>Information on fees and charges for visas and citizenship application costs is available on the Department's website at:</p>
                    <ul>
                        <li>Current visa pricing table</li>
                        <li>1298i - Citizenship fees</li>
                    </ul>

                    <h3>Action Required flag still showing</h3>
                    <p>Please disregard the action required flag if you have already provided the following information and it is still showing as required on your application summary page:</p>
                    <ul>
                        <li>Additional payment requirement</li>
                    </ul>
                    <p>This is a known system issue that is currently being investigated by technical teams and does not affect the processing of your application.</p>

                    <h3>Attach 'Facial Image' error</h3>
                    <p>Applicants for some visa and citizenship products are encountering an error when attaching a passport sized photograph to the application.</p>
                    <p>An error occurs on the 'Attach Documents' page of the application, when trying to attach an image under the heading 'Facial Image'. You may see the following error message:</p>
                    <ul>
                        <li><i>Mouth closed: the system is detecting that the person's mouth is open. Close the mouth so the lips are naturally together and are in a neutral position. Do not smile.</i><br/>
                            Click 'Choose Files' again to select a new image. The applicant will be given three (3) attempts to attach an image that meets Departmental guidelines. See 'Help' for further information</li>
                    </ul>
                    <p><b>Visa applicants -</b> If you have attached all other documents, to submit your application:</p>
                    <ul>
                        <li>Select 'next' to proceed to the application submission screen</li>
                        <li>You will be asked to explain why you cannot provide 'Supporting Evidence'. Enter the reason 'system error message when trying to attach facial image'</li>
                        <li>Select 'Confirm'</li>
                        <li>You will be able to submit your application and attach the passport photograph at a later date</li>
                    </ul>
                    <p><b>Citizenship applicants -</b> This is a mandatory document, to submit your application:</p>
                    <ul>
                        <li>Attempt three times to attach the photograph</li>
                        <li>Ensure all three attempts are done on the screen where the error displays</li>
                        <li>On the third attempt, the image will upload successfully</li>
                    </ul>
                    <p>Technical teams are investigating the issue. We apologise for any inconvenience.</p>
                </div>
            </div>

            <div className='buttonDiv'>
                <NavLink to='/app'>
                    <button name='continue'>Continue</button>
                </NavLink>
            </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default LoginSuccess
