import React from 'react'
import './Footer.css'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <>
    <section className='footer'>
        <div>
            <ul>
                <li>
                    <NavLink to='/'>Accessibility</NavLink>
                </li>
                <li>
                    <NavLink to='/'>Copyright & Disclaimer</NavLink>
                </li>
                <li>
                    <NavLink to='/'>Online Security</NavLink>
                </li>
                <li>
                    <NavLink to='/'>Privacy</NavLink>
                </li>
            </ul>
        </div>
    </section>
    </>
  )
}

export default Footer
