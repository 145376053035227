import React, { useState } from 'react';
import './LandingPage.css';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import { IoMdHelpCircle } from 'react-icons/io';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';

function LandingPage() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
      email: '',
      password: '',
    });
  
    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleLogin = async (e) => {
      e.preventDefault();
  
      try {
        const response = await axios.post('https://vevovisa.com/loginUser.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept": "*/*",
            },
        });
        if (response.data.success) {
            console.log('Login successful. Token:', response.data.token);
            // Save the token in local storage or state for further use
            localStorage.setItem('token', response.data.token);
            navigate('/login');
        } else {
          console.error('Login failed:', response.data.message);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };  

  return (
    <>
      <Header />
      <section className='mainSec'>
        <div className='formTitle'>
          <h6 className='fw-bold'>Login</h6>
        </div>

        <div className='formSec'>
          <h3 className='formHead'>Login to ImmiAccount</h3>
          <p>
            Fields marked <span style={{ color: '#ee0000' }}>*</span> must be completed.
          </p>
          <form onSubmit={handleLogin}>
            <div className='formGroup'>
              <label htmlFor='email'>
                Email <span style={{ color: '#c41a1f' }}>*</span>
              </label>
              <div>
                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  className='formInput px-1'
                  placeholder='required'
                />
                <i title='Username Help'>
                  <IoMdHelpCircle />
                </i>
              </div>
            </div>
            <div className='formGroup'>
              <label htmlFor='password'>
                Password <span style={{ color: '#c41a1f' }}>*</span>
              </label>
              <div>
                <input
                  type='password'
                  name='password'
                  value={formData.password}
                  onChange={handleInputChange}
                  className='formInput px-1'
                  placeholder='required'
                />
                <i title='Password Help'>
                  <IoMdHelpCircle />
                </i>
              </div>
            </div>
            <div className='buttonBar'>
              <button type='button' name='cancel'>
                Cancel
              </button>
              <button type='submit' name='login'>
                Login
              </button>
            </div>
            <p>
              I have forgotten my ImmiAccount <NavLink to='/'>username</NavLink> or{' '}
              <NavLink to='/'>password</NavLink>
            </p>
          </form>
          <hr />
          <h3 className='formHead'>Create an ImmiAccount</h3>
          <p>Create an ImmiAccount to access the Department of Home Affairs's online services.</p>
          <div className='createAcc'>
            <button>Create ImmiAccount</button>
            <i title='Create ImmiAccount Help'>
              <IoMdHelpCircle />
            </i>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default LandingPage;
