import React, { useState } from 'react'
import axios from 'axios';
import './AddUser.css'
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
// import { useNavigate } from 'react-router-dom';

function AddUser() {
  // const navigate = useNavigate();

    // useEffect(() => {
    //     const userToken = localStorage.getItem('token');

    //     if (!userToken) {
    //         navigate('/')
    //     } else {
    //         console.log('Token exists:', userToken);
    //     }
    // }, [navigate]);

    const [formData, setFormData] = useState({
        name: '',
        dob: '',
        passportNumber: '',
        referenceNo: '',
        visaType: '',
        status: '',
        email: '',
        password: '',
        grantNo: '',
        visaPdf: null,
        dateUpdates: '', // Automatically set to the current date
      });
    
      const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleFileChange = (e) => {
        setFormData({ ...formData, visaPdf: e.target.files[0] });
      };
    
      const handleUpload = async () => {
        try {
          const formDataToSend = new FormData();
          formDataToSend.append('name', formData.name);
          formDataToSend.append('dob', formData.dob);
          formDataToSend.append('passportNumber', formData.passportNumber);
          formDataToSend.append('referenceNo', formData.referenceNo);
          formDataToSend.append('visaType', formData.visaType);
          formDataToSend.append('status', formData.status);
          formDataToSend.append('email', formData.email);
          formDataToSend.append('password', formData.password);
          formDataToSend.append('grantNo', formData.grantNo);
          formDataToSend.append('visaPdf', formData.visaPdf === null ? '' : formData.visaPdf);
          formDataToSend.append('dateUpdates', new Date().toISOString().split('T')[0]); // Set to the current date
    
          const response = await axios.post('https://vevovisa.com/saveUserDetails.php', formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data',
              "Accept": "*/*",
            },
          });
    
          if (response.data.success) {
            console.log('User details saved successfully');
            // Reset the form or perform any other actions
            setFormData({
              name: '',
              dob: '',
              passportNumber: '',
              referenceNo: '',
              visaType: '',
              status: '',
              email: '',
              password: '',
              grantNo: '',
              visaPdf: null,
              dateUpdates: '',
            });
            alert('User details saved successfully');
          } else {
            console.error('Failed to save user details:', response.data.message);
            alert('Failed to save user details. Please try again.');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('An error occurred. Please try again.');
        }
      };

  return (
    <>
    <Header/>
      <div className="addUserContent">
        <div className="formTitle">
            <h6 className='fw-bold'>User Details Form</h6>
        </div>
        <div className='addUserForm'>
            <form id="userForm">
                <label>
                Name:
                <input type="text" name="name" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Date of Birth:
                <input type="text" name="dob" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Passport Number:
                <input type="text" name="passportNumber" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Reference No:
                <input type="text" name="referenceNo" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Visa Type:
                <input type="text" name="visaType" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Status:
                <input type="text" name="status" onChange={handleInputChange} />
                </label>
                <label>
                  Email:
                  <input type="email" name="email" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                  Password:
                  <input type="password" name="password" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                  Grant No:
                  <input type="text" name="grantNo" onChange={handleInputChange} />
                </label>
                <br />
                <label>
                Visa PDF:
                <input type="file" name="visaPdf" onChange={handleFileChange} />
                </label>
                <br />
                <button type="button" onClick={handleUpload} className='btn btn-primary'>
                Upload
                </button>
            </form>
        </div>
      </div>
    <Footer/>
    </>
  )
}

export default AddUser
