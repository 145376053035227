import React from 'react'
import './HeaderVevo.css'
import { NavLink } from 'react-router-dom'
import Logo from './../../Images/logoflat.png'

function HeaderVevo() {
  return (
    <>
      <section className='headerVevo px-3 d-flex align-items-center justify-content-between'>
        <NavLink to='/'>
            <img src={Logo} alt='Logo' />
        </NavLink>
        <div>
            <h1 className='text-white text-end'>VEVO for Visa Holders</h1>
        </div>
      </section>
    </>
  )
}

export default HeaderVevo
